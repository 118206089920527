
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { TypeData } from '@/types/projectFile'

@Component
export default class FileType extends Vue {
  @Prop({ default: false }) private isShowType!: boolean
  private addLine: number | null = null
  private tableData: TypeData[]=[]
  private loading = false
  private info: TypeData = this.initAddForm
  private nodeList = [
    {
      label: '项目进场前',
      value: '1'
    },
    {
      label: '施工作业中',
      value: '2'
    },
    {
      label: '验收完成',
      value: '3'
    },
    {
      label: '审计定案',
      value: '4'
    }
  ]

  @Watch('isShowType')
  onShowChange (value: boolean) {
    value && this.getTypeList()
  }

  get initAddForm () {
    return {
      typesName: '',
      timeNode: '',
      typeId: ''
    }
  }

  getTypeList () {
    this.loading = true
    this.$axios.get(this.$apis.projectFile.selectYhProjectFileTypesByList).then(res => {
      this.tableData = res || []
    }).finally(() => {
      this.loading = false
    })
  }

  addUpdate (index: number, row: TypeData) {
    if (this.addLine === index) {
      if (!this.info.typesName) {
        this.$message.warning('请输入文件类型名称')
        return
      }
      if (!this.info.timeNode) {
        this.$message.warning('请选择时间节点')
        return
      }
      const url = this.info.typeId ? this.$apis.projectFile.updateYhProjectFileTypes : this.$apis.projectFile.insertYhProjectFileTypes
      this.$axios.post(url, this.info).then(() => {
        this.$message.success(this.info.typeId ? '编辑文件类型成功' : '添加文件类型成功')
        this.addLine = null
        this.info = JSON.parse(JSON.stringify(this.initAddForm))
        this.getTypeList()
      })
    } else if (this.addLine !== null) {
      this.$message.warning('请先保存')
    } else {
      this.addLine = index
      this.info = { ...row }
    }
  }

  addFormNew () {
    if (!this.addLine && this.addLine !== 0) {
      this.info = JSON.parse(JSON.stringify(this.initAddForm))
      this.tableData.push(this.info)
      this.addLine = this.tableData.length - 1
      // 滚动到表格最底部
      this.$nextTick(() => {
        (this.$refs.addTable as any).bodyWrapper.scrollTop = (this.$refs.addTable as any).bodyWrapper.scrollHeight
      })
    } else {
      this.$message.warning('请先保存')
    }
  }

  onDelete (row: TypeData) {
    this.$confirm(`是否删除文件类型【${row.typesName}】?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.projectFile.deleteYhProjectFileTypes,
        {
          typeId: row.typeId
        }).then(() => {
        this.$message.success('删除成功')
        this.addLine = null
        this.getTypeList()
      })
    })
  }

  close () {
    // 数据清除
    this.addLine = null
    this.$emit('onClose')
    this.$emit('update:isShowType', false)
  }
}
