
import { Component, Vue } from 'vue-property-decorator'
import UploadFile from '@/components/uploadFile/Index.vue'
import { Info } from '@/types/projectFile'
import { FileInfo } from '@/types/common'
import { ElForm } from 'element-ui/types/form'
import FileType from './FileType.vue'

@Component({
  components: { FileType, UploadFile }
})
export default class ProjectFileList extends Vue {
  private searchInfo: { projectId: string } = {
    projectId: ''
  }

  private tableData = []
  private total = 0
  private page = 1
  private size = 10
  private loading = false
  private addShow = false
  private typeList = []
  private submitShow = false
  private nodeList = ['项目进场前', '施工作业中', '验收完成', '审计定案']
  private isShowType = false
  private info: Info = {
    fileTypeId: '',
    timeNode: '',
    projectId: '',
    comments: '',
    fileList: []
  }

  get projectList () {
    return this.$store.state.projectList
  }

  get normalProjectList () {
    return this.$store.state.normalProjectList
  }

  private rules = {
    fileTypeId: [{ required: true, message: '请选择文件类型', trigger: 'change' }],
    timeNode: [{ required: true, message: '请选择时间节点', trigger: 'change' }],
    projectId: [{ required: true, message: '请选择所属项目', trigger: 'change' }],
    fileList: [{ required: true, message: '请上传文件' }]
  }

  created () {
    this.getList()
  }

  // 搜索
  onSearch () {
    this.page = 1
    this.getList()
  }

  // 获取列表数据
  getList () {
    this.loading = true
    this.$axios.get(this.$apis.projectFile.selectYhProjectFilesCompilationByList,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.tableData = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  getTypeList () {
    this.info.fileTypeId = ''
    this.$axios.get(this.$apis.projectFile.selectYhProjectFileTypesByList, {
      timeNode: this.info.timeNode
    }).then(res => {
      this.typeList = res || []
    })
  }

  onType () {
    this.isShowType = true
  }

  onAdd () {
    this.addShow = true
  }

  onSuccess (file: FileInfo) {
    this.info.fileList = [file]
  }

  toDetail (row: any) {
    this.$router.push({
      name: 'projectFileDetail',
      params: {
        projectId: row.projectId
      }
    })
  }

  getState (state: string) {
    const arr = ['', '未开工', '在建', '已移交']
    return arr[Number(state)]
  }

  closeDialog () {
    this.info = {
      fileTypeId: '',
      timeNode: '',
      projectId: '',
      comments: '',
      fileList: []
    };
    (this.$refs.info as ElForm).resetFields()
    this.addShow = false
    this.onSearch()
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.projectFile.insertYhProjectFiles, this.info).then(() => {
          this.$message.success('保存成功')
          this.closeDialog()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
